<template>
  <div class="">
    <div class="w-auto" v-if="loading">
      <clip-loader :loading="loading" color="primary"></clip-loader>
    </div>
    <div v-else>
      <div v-if="crrTicket.object">
        <vx-card :title="'Visualisation ticket N° '+crrTicket.ref" class="mb-4">
          <template slot="actions">
            <vx-tooltip text="Retour" color="primary">
              <router-link class="ml-2 text-primary" :to="{ name: 'Ticket' }">
                <feather-icon
                  icon="CornerUpLeftIcon"
                  svgClasses="w-10 h-10"
                ></feather-icon>
              </router-link>
            </vx-tooltip>
          </template>
          <div slot="no-body" class="p-8">
            <vs-row vs-justify="space-between">
              <vs-col
                vs-justify="center"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="12"
                class="mb-8"
              >
                <div class="mb-4 inline-flex">
                  <div
                    class="h-5 w-5 inline-block rounded-full mr-2"
                    :style="{
                      background: crrBackgroundColor(
                        crrTicket.status
                      ).background
                    }"
                  ></div>
                  <span>{{
                    crrBackgroundColor(crrTicket.status)
                      .text
                  }}</span>
                </div>
                <div class="pt-2">
                  <span><strong> Objet :</strong></span>
                  <p class="p-2" style="overflow-wrap: anywhere;">
                    {{ crrTicket.object }}
                  </p>
                </div>
                <div class="pt-2">
                  <span ><strong> Message :</strong></span>
                  <p class="p-2" style="overflow-wrap: anywhere;">
                    {{ crrTicket.description }}
                  </p>
                </div>
                <div>
                  <div
                    v-if="crrTicket.filesname && crrTicket.filesname.length > 0"
                    class="mt-6 mb-3"
                    style="display: flex; flex-direction: column"
                  >
                    <div
                      v-for="(file, index) in crrTicket.filesname"
                      :key="index"
                      class="center"
                    >
                      <div class="flex">
                        <span class="mr-2">{{ index + 1 }})</span>
                        <div v-if="file.filename && file.name" class="mb-6">
                          <b @click="checkViewFile(file)" class="cursor-pointer">
                            {{ subStringTwo(file.name) }}
                          </b>
                        </div>
                        <div v-else class="mb-6">
                          <b>{{ subStringTwo(file) }}</b>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </vs-col>

              <vs-col
                vs-justify="center"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="12"
                class="mb-8 text-right"
              > 
                <div class="inline-flex">
                  <p>
                    <feather-icon
                      icon="InfoIcon"
                      class="text-primary"
                      svgClasses="w-6 h-6"
                    ></feather-icon>
                  </p>
                  <p class="ml-2">{{ dateElement(crrTicket).textInfo }}</p>
                </div>
              </vs-col>
            </vs-row>

            <vs-row>
              <vs-col
                vs-justify="center"
                vs-align="center"
                vs-lg="12"
                vs-sm="12"
                vs-xs="12"
                class="mb-6 "
              >
                <strong
                  >Commentaire(s): {{ crrTicket.commentsTicket.length }}</strong
                >
              </vs-col>
            </vs-row>

            <div class="mb-6">
              <div
                v-for="(comment, index) in crrTicket.commentsTicket"
                :key="index"
              >
                <vs-divider v-if="index > 0" />
                <div class="flex  align-items-end justify-between">
                  <p class="pb-1 pt-1 text-black-70 text-primary">
                    {{
                      comment.user.person.firstname +
                        " " +
                        comment.user.person.lastname
                    }}
                  </p>
                  <span style="font-size: x-small;">{{ dateElement(comment).textInfo }}</span>
                </div>

                <div>
                  <p style="overflow-wrap: anywhere;">{{ comment.content }}</p>
                </div>
                <div>
                  <div>
                  <div
                    v-if="comment.filesname && comment.filesname.length > 0"
                    class="mt-6 mb-3"
                    style="display: flex; flex-direction: column"
                  >
                    <div
                      v-for="(file, index) in comment.filesname"
                      :key="index"
                      class="center"
                    >
                      <div class="flex">
                        <span class="mr-2">{{ index + 1 }})</span>
                        <div v-if="file.filename && file.name" class="mb-4">
                          <b @click="checkViewFile(file)" class="cursor-pointer">
                            {{ subStringTwo(file.name) }}
                          </b>
                        </div>
                        <div v-else class="mb-2">
                          <b>{{ subStringTwo(file) }}</b>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </div>

                <div v-if="crrTicket.status !== 'close'">
                  <div
                    v-if="comment.user.id == user.id"
                    style="font-size: x-small"
                    class="inline-flex mt-4"
                  >
                    <div>
                      <vx-tooltip
                        color="primary"
                        text="Editer"
                      >
                        <div class="cursor-pointer ml-4">
                          <feather-icon
                            @click="
                              checkUpdateComment(
                                comment
                              )
                            "
                            icon="Edit2Icon"
                            svgClasses="w-6 h-6"
                          ></feather-icon>
                        </div>
                      </vx-tooltip>
                    </div>

                    <div>
                      <vx-tooltip
                        color="primary"
                        text="Supprimer"
                      >
                        <div class="cursor-pointer ml-4">
                          <feather-icon
                            @click="
                              checkRemoveComment(
                                comment
                              )
                            "
                            icon="Trash2Icon"
                            svgClasses="w-6 h-6"
                          ></feather-icon>
                        </div>
                      </vx-tooltip>
                    </div>
                    <div v-if="comment.id === crrID">
                      <div class="w-auto" v-if="loadingRemoveComment">
                        <clip-loader :loading="loadingRemoveComment" color="primary"></clip-loader>
                      </div>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
            <div v-if="crrTicket.status !== 'close'">
              <div>
                <div class="con-exemple-prompt mb-8">
                  <div class="w-full">
                    <vs-textarea
                      v-model="form.message"
                      placeholder="Écrivez votre message* : "
                      v-validate="'required'"
                      name="commentaire"
                    />
                    <span class="text-danger text-sm">
                      {{ errors.first("commentaire") }}
                    </span>
                  </div>
                </div>
                <vs-col
                  vs-justify="center"
                  vs-align="center"
                  vs-lg="12"
                  vs-sm="12"
                  vs-xs="12"
                  class="mb-6"
                >
                  <div
                    class="w-full"
                    style="
                      display: flex;
                      align-items: flex-end;
                      justify-content: space-between;
                    "
                  >
                    <div>
                      <div>
                        <div class="mb-4 ml-2">
                          <span>Fichier(s)</span>
                        </div>
                        <div>
                          <div uk-form-custom class="ebsi-upload-file">
                            <input
                              multiple
                              type="file"
                              :value="resetFilesUpload"
                              v-on:change="checkUploadFile"
                            />
                            <button
                              style="cursor: pointer"
                              class="uk-button uk-button-default"
                              type="button"
                              tabindex="-1"
                            >
                              Choisir vos fichiers
                            </button>
                          </div>
                        </div>
                      </div>

                      <span class="text-danger text-sm" v-show="formError"
                        >Erreur lors du chargement du fichier</span
                      >
                    </div>
                  </div>

                  <div
                    class="mt-4"
                    style="display: flex; flex-direction: column"
                  >
                    <div
                      v-for="(file, index) in form.filesname"
                      :key="index"
                      class="center"
                    >
                      <div class="flex">
                        {{ index + 1 }})
                        <div v-if="file.filesname && file.name">
                          <b
                            @click="checkViewFile(file)"
                            class="cursor-pointer"
                          >
                            {{ subStringTwo(file.name) }}
                          </b>
                        </div>
                        <div v-else>
                          <b>{{ subStringTwo(file) }}</b>
                        </div>

                        <vx-tooltip color="primary" text="Supprimer">
                          <div
                            class="ml-8 cursor-pointer"
                            @click="removeFile(index)"
                          >
                            <feather-icon
                              icon="Trash2Icon"
                              svgClasses="w-6 h-6"
                            ></feather-icon>
                          </div>
                        </vx-tooltip>
                      </div>
                    </div>
                  </div>
                </vs-col>

                <div class="pt-2 pb-2">
                  <div class="inline-flex">
                    <div>
                      <vs-button
                        :disabled="!validateFormComment || loadingAddComment"
                        @click="addComment"
                        class="w-full sm:w-auto"
                        >Envoyer</vs-button
                      >
                    </div>
                    <div class="w-auto" v-if="loadingAddComment">
                      <clip-loader
                        :loading="loadingAddComment"
                        color="primary"
                      ></clip-loader>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </vx-card>
        <vs-popup
          class="holamundo"
          title="Modifier un commentaire"
          :active.sync="popupUpdatedComment"
        >
          <div class="m-4">
            <div class="clearfix">
              <div>
                <vs-row vs-w="12">
                  <vs-col
                    class="pr-4 mb-4"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-lg="12"
                    vs-sm="12"
                    vs-xs="12"
                  >
                    <div class="w-full">
                      <span>
                        <strong>Commentaire</strong>
                      </span>
                      <vs-textarea
                        v-model="formUpdated.message"
                        placeholder="Écrivez votre message* : "
                        v-validate="'required'"
                        name="commentaire"
                      />
                      <span class="text-danger text-sm">
                        {{ errors.first("commentaire") }}
                      </span>
                    </div>
                  </vs-col>

                  <vs-col
                    vs-justify="center"
                    vs-align="center"
                    vs-lg="12"
                    vs-sm="12"
                    vs-xs="12"
                    class="mb-6"
                  >
                    <div
                      class="w-full"
                      style="
                        display: flex;
                        align-items: flex-end;
                        justify-content: space-between;
                      "
                    >
                      <div>
                        <div>
                          <div class="mb-4 ml-2">
                            <span>Fichier(s)</span>
                          </div>
                          <div>
                            <div uk-form-custom class="ebsi-upload-file">
                              <input
                                multiple
                                type="file"
                                :value="resetFilesUpload"
                                v-on:change="checkUploadFileUpdated"
                              />
                              <button
                                style="cursor: pointer"
                                class="uk-button uk-button-default"
                                type="button"
                                tabindex="-1"
                              >
                                Choisir vos fichiers
                              </button>
                            </div>
                          </div>
                        </div>

                        <span class="text-danger text-sm" v-show="formError"
                          >Erreur lors du chargement du fichier</span
                        >
                      </div>
                    </div>

                    <div
                      class="mt-6 mb-3"
                      style="display: flex; flex-direction: column"
                    >
                      <div
                        v-for="(file, index) in formUpdated.filesname"
                        :key="index"
                        class="center"
                      >
                        <div class="flex">
                          {{ index + 1 }})
                          <div v-if="file.filename && file.name">
                            <b
                              @click="checkViewFile(file)"
                              class="cursor-pointer"
                              >{{ subStringTwo(file.name) }}</b
                            >
                          </div>
                          <div v-else>
                            <b>{{ subStringTwo(file) }}</b>
                          </div>

                          <vx-tooltip color="primary" text="Supprimer">
                            <div
                              class="ml-8 cursor-pointer"
                              @click="removeFileUpdated(index)"
                            >
                              <feather-icon
                                icon="Trash2Icon"
                                svgClasses="w-6 h-6"
                              ></feather-icon>
                            </div>
                          </vx-tooltip>
                        </div>
                      </div>
                      <div class="mt-2">
                        <p class="text-danger">* Champ obligatoire</p>
                      </div>
                    </div>
                  </vs-col>
                </vs-row>
              </div>
            </div>
            <div class="mt-2">
              <div class="inline-flex">
                <div>
                  <vs-button
                    class="w-full sm:w-auto"
                    :disabled="loadingUpdatedComment"
                    @click="UpdatedComment"
                    >Valider</vs-button
                  >
                  <vs-button
                    class="w-full sm:w-auto ml-4"
                    type="border"
                    @click="popupUpdatedComment = false"
                    >Annuler</vs-button
                  >
                </div>
                <div class="ml-2">
                  <clip-loader
                    :loading="loadingUpdatedComment"
                    color="primary"
                  ></clip-loader>
                </div>
              </div>
            </div>
          </div>
        </vs-popup>
      </div>
      <div v-else>
        <h2 class="text-danger m-3">{{ errorMsg }}</h2>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { en, fr } from "vuejs-datepicker/dist/locale";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import { mixin } from "@/mixins/mixin";
import role from "@/config/role";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

export default {
  data() {
    return {
      loadingAddComment: false,
      crrID: [],
      loadingRemoveComment: false,
      form: {
        message: "",
        filesname: [],
      },
      formUpdated: {
        message: "",
        filesname: [],
      },
      popupUpdatedComment: false,
      filesListBeforeUpdated: [],
      formData: [],
      fileDataUp: [],
      formDataUpdated: [],
      fileDataUpUpdated: [],
      resetFilesUpload: "",
      loadingUpdatedComment: false,
      formError: false,
      loading: false
    };
  },
  components: {
    quillEditor,
    ClipLoader
  },
  created() {
    Validator.localize("fr", VeeValidateFR);
    if (this.user.type != "super_administrator_sys" && !this.user.person.userAcces.chk_ticket) {
      this.$router.push({ name: "Home" })
    }
    this.getTicket({ ticketId: this.$route.params.ticketId });
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("ticket", ["crrTicket"]),
    validateFormComment() {
      return !this.errors.any() && this.form.message != "";
    }
  },

  methods: {
    ...mapActions("ticket", ["applyAddComment", "applyPutComment", "applyGetTicket", "applyRemoveComment"]),
    ...mapActions("general", ["applyUploadFile"]),

    aboutElement(data) {
      return mixin.methods.aboutElement(data);
    },
    dateElement(data) {
      return mixin.methods.dateElement(data);
    },
    subStringTitle(data) {
      if (data.length > 66) {
        return `${data.substring(0, 66)} ...`;
      } else {
        return data;
      }
    },
    subStringTwo(data) {
      if (data.length > 50) {
        return `${data.substring(0, 50)} ...`;
      } else {
        return data;
      }
    },
    checkViewFile(item) {
      window.open(this.showFileData(item.filename), "_blank");
    },
    showFileData(data) {
      return `${this.$config.serverURL}/downloads/${data}`;
    },
    crrBackgroundColor(stat) {
      let color = { background: "", text: "", color: "" };
      switch (stat) {
        case "new":
          color = { background: "#F2E205", text: "Nouveau ", color: "white" };
          break;
        case "answered":
          color = { background: "#378C0F", text: " Répondu ", color: "white" };
          break;
        case "close":
          color = { background: "#D91828", text: " Fermé", color: "white" };
          break;

        default:
          break;
      }
      return color;
    },
    async getTicket(item) {
      this.loading = true;

      try {
        await this.applyGetTicket(item);
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach(item => {
              switch (item.code) {
                case "ticketNotFound":
                  this.errorMsg = "Ce ticket n'existe pas";
                  this.acceptAlert("danger", "Ticket", this.errorMsg);
                  break;

                default:
                  this.acceptAlert(
                    "danger",
                    "Ticket",
                    "Une erreur s'est produite"
                  );
                  break;
              }
            });
          }
        }
      }
    },

   acceptAlert(data) {
      let newdata = {...data}
      this.$vs.notify(newdata);
    },

    removeFileUpdated(data) {
      this.formDataUpdated.splice(data, 1);
      this.formUpdated.filesname.splice(data, 1);
    },

    checkUploadFile(evt) {
      const evtLength = evt.target.files.length;
      let allFilesChecked = [];
      let formDataLength = this.formData.length;
      for (let i = 0; i < evtLength; i++) {
        let formDataTmp = new FormData();
        formDataTmp.append("uri", evt.target.files[i]);
        this.formData[formDataLength + 1] = formDataTmp;
        this.formError = false;
        allFilesChecked.push(evt.target.files[i].name);
        formDataLength++;
      }
      if (this.form.filesname) {
        this.form.filesname = [
          ...this.form.filesname,
          ...allFilesChecked,
        ];
      } else {
        this.form.filesname = [...allFilesChecked];
      }
    },

    checkUploadFileUpdated(evt) {
      const evtLength = evt.target.files.length;
      let allFilesChecked = [];
      let formDataLength = this.formDataUpdated.length;
      for (let i = 0; i < evtLength; i++) {
        let formDataTmp = new FormData();
        formDataTmp.append("uri", evt.target.files[i]);
        this.formDataUpdated[formDataLength + 1] = formDataTmp;
        this.formError = false;
        allFilesChecked.push(evt.target.files[i].name);
        formDataLength++;
      }
      if (this.formUpdated.filesname) {
        this.formUpdated.filesname = [
          ...this.formUpdated.filesname,
          ...allFilesChecked,
        ];
      } else {
        this.formUpdated.filesname = [...allFilesChecked];
      }
    },

    async uploadedFilesUp() {
      let _this = this;
      let productPromisesUp = [];
      this.formDataUpdated.forEach(function (ele, index) {
        productPromisesUp.push(
          (async function (ele) {
            const resUp = await _this.applyUploadFile(ele);
            _this.fileDataUpUpdated.push({
              filename: resUp.data[0].uploadedName,
              name: resUp.data[0].filename,
            });
          })(ele)
        );
      });
      await Promise.all(productPromisesUp);
    },

    checkUpdateComment(item) {
      this.formUpdated.filesname = [];

      this.formUpdated = {
        ...item,
        message: item.content,
      };
      this.filesListBeforeUpdated = this.formUpdated.filesname;
      this.crrID = item.id;
      this.popupUpdatedComment = true;
    },

    async UpdatedComment() {
      this.loadingUpdatedComment = true;

      if (this.formUpdated.filesname && this.formUpdated.filesname.length > 0) {
        await this.uploadedFilesUp()
          .then((resUp) => {
            setTimeout(async () => {
              this.acceptAlert(
                mixin.methods.message("ticket", "file").success
              );

              this.fetchApplyUpdatedComment();
            }, 1000);
          })
          .catch((err) => {
            this.acceptAlert(
              mixin.methods.message("ticket", "file").error
            );

            this.fetchApplyUpdatedComment();
          });
      } else {
        this.fetchApplyUpdatedComment();
      }
    },
    async fetchApplyUpdatedComment() {
      this.loadingUpdatedComment = true;

      let data = {
        commentId: this.formUpdated.id,
        ...this.formUpdated,
        content: this.formUpdated.message,
        filesname: [],
      };
      console.log("data", data)
      if (this.formUpdated.filesname) {
        data.filesname = [...this.filesListBeforeUpdated, ...this.fileDataUpUpdated];
      } else {
        data.filesname = [...this.fileDataUpUpdated];
      }
      console.log("data", data)
      try {
        await this.applyPutComment(data);
        this.acceptAlert(
          mixin.methods.message("ticket", "commentUpdate").success
        );
        this.formUpdated = {
          message: "",
          filesname: [],
        };
        this.formDataUpdated = [];
        this.fileDataUpUpdated = [];
        this.filesListBeforeUpdated = [];
        this.loadingUpdatedComment = false;
        this.popupUpdatedComment = false;
      } catch (err) {
        this.loadingUpdatedComment = false;
        this.error = true;
        this.errorMsg = "";
      }
    },

    checkRemoveComment(comm) {
      this.crrID = comm.id;
      this.$vs.dialog({
        type: "confirm",
        color: "primary",
        title: `Confirmation`,
        text: `Voulez-vous vraiment supprimer ce commentaire?`,
        acceptText: "Confirmer",
        cancelText: "Annuler",
        accept: this.fetchApplyRemoveComment,
      });
    }, 
    async fetchApplyRemoveComment() {
      this.loadingRemoveComment = true;
      try {
        let data = {
          ids: [this.crrID],
        };
        await this.applyRemoveComment(data);

        this.acceptAlert(mixin.methods.message("ticket", "commentDelete").success);
        this.loadingRemoveComment = false;

      } catch (err) {
        this.loadingRemoveComment = false;
        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              this.acceptAlert(mixin.methods.message("ticket", "commentDelete").error);
            });
          }
        }
      }
    },

    async uploadedFiles() {
      let _this = this;
      let productPromises = [];
      this.formData.forEach(function (ele, index) {
        productPromises.push(
          (async function (ele) {
            const res = await _this.applyUploadFile(ele);
            _this.fileDataUp.push({
              filename: res.data[0].uploadedName,
              name: res.data[0].filename,
            });
          })(ele)
        );
      });
      await Promise.all(productPromises);
    },
    async addComment() {
      this.loadingAddComment = true;

      if (this.form.filesname && this.form.filesname.length > 0) {
        await this.uploadedFiles()
          .then((res) => {
            this.acceptAlert(mixin.methods.message("ticket", "file").success);

            this.fetchApplyAddComment();
          })
          .catch((err) => {
            this.acceptAlert(mixin.methods.message("ticket", "file").error);

            this.fetchApplyAddComment();
          });
      } else {
        this.fetchApplyAddComment();
      }
    },
    async fetchApplyAddComment() {
      this.loadingAddComment = true;
      if (!this.validateFormComment) return false;
      try {
        await this.applyAddComment({
          ticketId: this.$route.params.ticketId,
          content: this.form.message,
          filesname: this.fileDataUp,
        });

        this.loadingAddComment = false;

        this.acceptAlert(mixin.methods.message("ticket", "comment").success);

        this.form = {
          message: "",
          filesname: [],
        };
        this.formData = [];
        this.fileDataUp = [];
      } catch (err) {
        this.loadingAddComment = false;
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                // case "userNotFound":
                //   this.errorMsg = "Votre nom d'utilisateur n'existe pas";
                //   this.acceptAlert("danger", "Utilisateurs", this.errorMsg);
                //   break;

                default:
                  this.acceptAlert(
                    "danger",
                    "Utilisateurs",
                    "Une erreur s'est produite"
                  );
                  break;
              }
            });
          }
        }
      }
    },
  }
};
</script>
